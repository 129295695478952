<template>
  <div class="bg-gray-50 dark:bg-notion-dark py-8">
    <div class="md:max-w-5xl md:mx-auto w-full">
      <div class="my-5 text-center">
        <h3 class="font-semibold text-3xl">
          And many more features
        </h3>
        <p class="w-full mt-2 mb-8">
          OpnForm makes form building easy and comes with powerful features.
        </p>
        <div
          class="grid grid-cols-2 md:grid-cols-3 mt-10 mb-5 ml-5 md:ml-0 px-4"
        >
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 3.5H17.8C18.9201 3.5 19.4802 3.5 19.908 3.71799C20.2843 3.90973 20.5903 4.21569 20.782 4.59202C21 5.01984 21 5.57989 21 6.7V8C21 8.93188 21 9.39783 20.8478 9.76537C20.6448 10.2554 20.2554 10.6448 19.7654 10.8478C19.3978 11 18.9319 11 18 11M12.5 18.5H5.2C4.0799 18.5 3.51984 18.5 3.09202 18.282C2.71569 18.0903 2.40973 17.7843 2.21799 17.408C2 16.9802 2 16.4201 2 15.3V14C2 13.0681 2 12.6022 2.15224 12.2346C2.35523 11.7446 2.74458 11.3552 3.23463 11.1522C3.60218 11 4.06812 11 5 11M9.8 13.5H13.2C13.48 13.5 13.62 13.5 13.727 13.4455C13.8211 13.3976 13.8976 13.3211 13.9455 13.227C14 13.12 14 12.98 14 12.7V9.3C14 9.01997 14 8.87996 13.9455 8.773C13.8976 8.67892 13.8211 8.60243 13.727 8.5545C13.62 8.5 13.48 8.5 13.2 8.5H9.8C9.51997 8.5 9.37996 8.5 9.273 8.5545C9.17892 8.60243 9.10243 8.67892 9.0545 8.773C9 8.87996 9 9.01997 9 9.3V12.7C9 12.98 9 13.12 9.0545 13.227C9.10243 13.3211 9.17892 13.3976 9.273 13.4455C9.37996 13.5 9.51997 13.5 9.8 13.5ZM17.3 21H20.7C20.98 21 21.12 21 21.227 20.9455C21.3211 20.8976 21.3976 20.8211 21.4455 20.727C21.5 20.62 21.5 20.48 21.5 20.2V16.8C21.5 16.52 21.5 16.38 21.4455 16.273C21.3976 16.1789 21.3211 16.1024 21.227 16.0545C21.12 16 20.98 16 20.7 16H17.3C17.02 16 16.88 16 16.773 16.0545C16.6789 16.1024 16.6024 16.1789 16.5545 16.273C16.5 16.38 16.5 16.52 16.5 16.8V20.2C16.5 20.48 16.5 20.62 16.5545 20.727C16.6024 20.8211 16.6789 20.8976 16.773 20.9455C16.88 21 17.02 21 17.3 21ZM2.3 6H5.7C5.98003 6 6.12004 6 6.227 5.9455C6.32108 5.89757 6.39757 5.82108 6.4455 5.727C6.5 5.62004 6.5 5.48003 6.5 5.2V1.8C6.5 1.51997 6.5 1.37996 6.4455 1.273C6.39757 1.17892 6.32108 1.10243 6.227 1.0545C6.12004 1 5.98003 1 5.7 1H2.3C2.01997 1 1.87996 1 1.773 1.0545C1.67892 1.10243 1.60243 1.17892 1.5545 1.273C1.5 1.37996 1.5 1.51997 1.5 1.8V5.2C1.5 5.48003 1.5 5.62004 1.5545 5.727C1.60243 5.82108 1.67892 5.89757 1.773 5.9455C1.87996 6 2.01997 6 2.3 6Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Form logic
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 11H21.5M1.5 11C1.5 16.5228 5.97715 21 11.5 21M1.5 11C1.5 5.47715 5.97715 1 11.5 1M21.5 11C21.5 16.5228 17.0228 21 11.5 21M21.5 11C21.5 5.47715 17.0228 1 11.5 1M11.5 1C14.0013 3.73835 15.4228 7.29203 15.5 11C15.4228 14.708 14.0013 18.2616 11.5 21M11.5 1C8.99872 3.73835 7.57725 7.29203 7.5 11C7.57725 14.708 8.99872 18.2616 11.5 21"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            URL pre-fill
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99999 1L4.99999 19M16 1L13 19M19 6H2M18 14H1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Unique submission ID
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 23 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2429 3.09232C10.6494 3.03223 11.0686 3 11.5004 3C16.6054 3 19.9553 7.50484 21.0807 9.28682C21.2169 9.5025 21.285 9.61034 21.3231 9.77667C21.3518 9.90159 21.3517 10.0987 21.3231 10.2236C21.2849 10.3899 21.2164 10.4985 21.0792 10.7156C20.7793 11.1901 20.3222 11.8571 19.7165 12.5805M6.22432 4.71504C4.06225 6.1817 2.59445 8.21938 1.92111 9.28528C1.78428 9.50187 1.71587 9.61016 1.67774 9.77648C1.6491 9.9014 1.64909 10.0984 1.67771 10.2234C1.71583 10.3897 1.78393 10.4975 1.92013 10.7132C3.04554 12.4952 6.39541 17 11.5004 17C13.5588 17 15.3319 16.2676 16.7888 15.2766M2.50042 1L20.5004 19M9.3791 7.87868C8.8362 8.42157 8.50042 9.17157 8.50042 10C8.50042 11.6569 9.84356 13 11.5004 13C12.3288 13 13.0788 12.6642 13.6217 12.1213"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Hidden fields
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 8V6C14.5 3.23858 12.2614 1 9.5 1C6.73858 1 4.5 3.23858 4.5 6V8M9.5 12.5V14.5M6.3 19H12.7C14.3802 19 15.2202 19 15.862 18.673C16.4265 18.3854 16.8854 17.9265 17.173 17.362C17.5 16.7202 17.5 15.8802 17.5 14.2V12.8C17.5 11.1198 17.5 10.2798 17.173 9.63803C16.8854 9.07354 16.4265 8.6146 15.862 8.32698C15.2202 8 14.3802 8 12.7 8H6.3C4.61984 8 3.77976 8 3.13803 8.32698C2.57354 8.6146 2.1146 9.07354 1.82698 9.63803C1.5 10.2798 1.5 11.1198 1.5 12.8V14.2C1.5 15.8802 1.5 16.7202 1.82698 17.362C2.1146 17.9265 2.57354 18.3854 3.13803 18.673C3.77976 19 4.61984 19 6.3 19Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Form password
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 13C14.8431 13 13.5 14.3431 13.5 16C13.5 17.6569 14.8431 19 16.5 19C18.1569 19 19.5 17.6569 19.5 16C19.5 14.3431 18.1569 13 16.5 13ZM16.5 13V6C16.5 5.46957 16.2893 4.96086 15.9142 4.58579C15.5391 4.21071 15.0304 4 14.5 4H11.5M4.5 7C6.15685 7 7.5 5.65685 7.5 4C7.5 2.34315 6.15685 1 4.5 1C2.84315 1 1.5 2.34315 1.5 4C1.5 5.65685 2.84315 7 4.5 7ZM4.5 7V19"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Webhooks
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 13L16 10L13 7M8 7L5 10L8 13M6.3 19H14.7C16.3802 19 17.2202 19 17.862 18.673C18.4265 18.3854 18.8854 17.9265 19.173 17.362C19.5 16.7202 19.5 15.8802 19.5 14.2V5.8C19.5 4.11984 19.5 3.27976 19.173 2.63803C18.8854 2.07354 18.4265 1.6146 17.862 1.32698C17.2202 1 16.3802 1 14.7 1H6.3C4.61984 1 3.77976 1 3.13803 1.32698C2.57354 1.6146 2.1146 2.07354 1.82698 2.63803C1.5 3.27976 1.5 4.11984 1.5 5.8V14.2C1.5 15.8802 1.5 16.7202 1.82698 17.362C2.1146 17.9265 2.57354 18.3854 3.13803 18.673C3.77976 19 4.61984 19 6.3 19Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Custom code
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.5 7H1.5M14.5 1V4M6.5 1V4M10.5 17V11M7.5 14H13.5M6.3 21H14.7C16.3802 21 17.2202 21 17.862 20.673C18.4265 20.3854 18.8854 19.9265 19.173 19.362C19.5 18.7202 19.5 17.8802 19.5 16.2V7.8C19.5 6.11984 19.5 5.27976 19.173 4.63803C18.8854 4.07354 18.4265 3.6146 17.862 3.32698C17.2202 3 16.3802 3 14.7 3H6.3C4.61984 3 3.77976 3 3.13803 3.32698C2.57354 3.6146 2.1146 4.07354 1.82698 4.63803C1.5 5.27976 1.5 6.11984 1.5 7.8V16.2C1.5 17.8802 1.5 18.7202 1.82698 19.362C2.1146 19.9265 2.57354 20.3854 3.13803 20.673C3.77976 21 4.61984 21 6.3 21Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Closing date
          </div>
          <div class="flex font-semibold my-3">
            <svg
              class="w-5 h-5 mr-2 text-nt-blue"
              viewBox="0 0 19 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 3C10.0523 3 10.5 2.55228 10.5 2C10.5 1.44772 10.0523 1 9.5 1C8.94772 1 8.5 1.44772 8.5 2C8.5 2.55228 8.94772 3 9.5 3Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 10C10.0523 10 10.5 9.55228 10.5 9C10.5 8.44772 10.0523 8 9.5 8C8.94772 8 8.5 8.44772 8.5 9C8.5 9.55228 8.94772 10 9.5 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 17C10.0523 17 10.5 16.5523 10.5 16C10.5 15.4477 10.0523 15 9.5 15C8.94772 15 8.5 15.4477 8.5 16C8.5 16.5523 8.94772 17 9.5 17Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 3C17.0523 3 17.5 2.55228 17.5 2C17.5 1.44772 17.0523 1 16.5 1C15.9477 1 15.5 1.44772 15.5 2C15.5 2.55228 15.9477 3 16.5 3Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 10C17.0523 10 17.5 9.55228 17.5 9C17.5 8.44772 17.0523 8 16.5 8C15.9477 8 15.5 8.44772 15.5 9C15.5 9.55228 15.9477 10 16.5 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 17C17.0523 17 17.5 16.5523 17.5 16C17.5 15.4477 17.0523 15 16.5 15C15.9477 15 15.5 15.4477 15.5 16C15.5 16.5523 15.9477 17 16.5 17Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.5 3C3.05228 3 3.5 2.55228 3.5 2C3.5 1.44772 3.05228 1 2.5 1C1.94772 1 1.5 1.44772 1.5 2C1.5 2.55228 1.94772 3 2.5 3Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.5 10C3.05228 10 3.5 9.55228 3.5 9C3.5 8.44772 3.05228 8 2.5 8C1.94772 8 1.5 8.44772 1.5 9C1.5 9.55228 1.94772 10 2.5 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.5 17C3.05228 17 3.5 16.5523 3.5 16C3.5 15.4477 3.05228 15 2.5 15C1.94772 15 1.5 15.4477 1.5 16C1.5 16.5523 1.94772 17 2.5 17Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            And much more...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  methods: {},
}
</script>
